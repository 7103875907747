<template>
    <form-modal
        ref="formModal"
        :title="i18nNewExport"
        size="xl"
        :in-editing="true"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nExportKmJob"
                    :operation="'created'"
                />
                <b-tabs
                    id="businessunit-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated"
                >
                    <b-tab :title="i18nInformation">
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nDate"
                                    label-for="filter-started-at-date-time"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_period')">
                                    <date-range-picker
                                        :child-class="validationClass($v.modal.start_date)"
                                        :value.sync="dateRange"
                                        :max-date="new Date()"
                                        :max-range-in-days="maxRangeInDays"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <cancel-button
                ref="cancelButton"
                child-class="col-sm-4 float-left"
                :disabled="submitLoading"
                @onClick="handleCancel"
            />
            <save-button
                ref="okButton"
                child-class="col-sm-4 float-right ml-3"
                :busy="submitLoading"
                :title="i18nGenerateReport"
                @onClick="handleOk"
            />
        </template>
    </form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import CancelButton from '@/src/components/CancelButton'
import SaveButton from '@/src/components/SaveButton'
import DateRangePicker from '@/src/components/DateRangePicker'
import filterVuetable from '@src/mixins/filterVuetable'
import ExportJobService from '@src/services/ExportJobService'
import moment from 'moment';

const i18nKey = 'EXPORT_KM_JOBS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        DateRangePicker,
        CancelButton,
        SaveButton,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation,
        filterVuetable,
    ],
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            modal: {
                start_date: moment().startOf('day').toDate(),
                end_date: moment().startOf('day').toDate(),
            },
            dateRange: {},
            maxRangeInDays: 30,
            submitLoading: false,
            tabIndex: 0,
        }
    },
    validations: {
        modal: {
            start_date: {
                required,
                beforeEndDate(value, model) {
                    return value <= model.end_date
                },
                maxRangePeriod(value, model) {
                    return ((model.end_date - value) / 86400000) <= this.maxRangeInDays;
                },
            },
            end_date: {
                required,
                afterStartDate(value, model) {
                    return value >= model.start_date
                },
            },
        },
    },
    computed: {
        i18nDate() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nNewExport() {
            return this.getI18n(i18nKey, 'BUTTONS.new_export')
        },
        i18nGenerateReport() {
            return this.getI18n(i18nKey, 'BUTTONS.generate_report')
        },
        i18nExportKmJob() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.export_km_job',
                modifier: 2,
            })
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
    },
    watch: {
        dateRange: {
            deep: true,
            handler(newValue) {
                if (newValue) {
                    this.modal.start_date = newValue.start
                    this.modal.end_date = newValue.end

                    this.$v.modal.start_date.$touch()
                    this.$v.modal.end_date.$touch()
                }
                else {
                    this.modal.start_date = null
                    this.modal.end_date = null
                }
            },
        },
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (oldTabIndex === 0) {
                this.$v.modal.$touch()
                if (this.$v.modal.$invalid) {
                    event.preventDefault();
                }
            }
        },
        showModal() {
            this.submitLoading = false
            this.tabIndex = 0
            this.cleanModal()
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.dateRange = {
                start: moment().startOf('day').toDate(),
                end: moment().startOf('day').toDate(),
            }

            this.modal = Object.assign(this.modal, {
                start_date: moment().startOf('day').toDate(),
                end_date: moment().startOf('day').toDate(),
            })
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleCancel() {
            this.$refs.confirmationFormModal.showModal(true, this.handleClose)
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleOk() {
            this.$v.modal.$touch()
            if (!this.$v.modal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const parameters = this.formatParameters({
                datetime_in: {
                    from: moment(this.modal.start_date).format('YYYY-MM-DD'),
                    to: moment(this.modal.end_date).format('YYYY-MM-DD'),
                },
                export_type: 'work_schedule_km',
                language: 'pt_BR',
            })

            const response = await ExportJobService.create(parameters)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nExportKmJob, 'created')
                this.$router.push({ name: 'exportJobs', params: { newJobCreated: true } })
            }

            if (statusCode === '417') {
                this.warningFeedback(this.i18nExportKmJob, response.data.message)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }

            this.submitLoading = false
        },
    },
}
</script>
